import { MenuItemType, PageType } from "."

import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import useTranslations from "components/use-translations"

const SoftwareBreadcrumb = ({ parentPage, activeMenuItem }) => {
  const getItemText = () => {
    switch (activeMenuItem) {
      case MenuItemType.Ozellikler:
        return ozellikler
      case MenuItemType.SikSorulanSorular:
        return sikSorulanSorular
      case MenuItemType.EkranGoruntuleri:
        return ekranGoruntuleri
      case MenuItemType.KullanimKilavuzu:
        return kullanimKilavuzu
      case MenuItemType.Referanslar:
        return referanslar
      case MenuItemType.Paketler:
        return paketler
      default:
        return ozellikler
    }
  }
  const getPageText = () => {
    switch (parentPage) {
      case PageType.Kutuphane:
        return kutuphaneProgrami
      case PageType.CekilisSite:
        return cekilisSitesi
      case PageType.CekilisProgram:
        return cekilisProgrami
      case PageType.AkilliKilit:
        return aklliKilit
      case PageType.Servishane:
        return servishaneTeknikServisProgrami
      default:
        return kutuphaneProgrami
    }
  }

  const {
    genel: {
      kullanimKilavuzu,
      ozellikler,
      sikSorulanSorular,
      ekranGoruntuleri,
      referanslar,
      paketler,
      anasayfa,
      kutuphaneProgrami,
      cekilisSitesi,
      cekilisProgrami,
      aklliKilit,
      servishaneTeknikServisProgrami,
    },
  } = useTranslations()
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb bg-white pl-0 ml-0 small">
        <li className="breadcrumb-item">
          <LocalizedLink to="/">{anasayfa}</LocalizedLink>
        </li>
        <li className="breadcrumb-item">
          <LocalizedLink to={`/${parentPage}/`}>{getPageText()}</LocalizedLink>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {getItemText()}
        </li>
      </ol>
    </nav>
  )
}

SoftwareBreadcrumb.propTypes = {
  parentPage: PropTypes.string,
  activeMenuItem: PropTypes.string,
}

export default SoftwareBreadcrumb
