import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import useTranslations from "components/use-translations"

export const MenuItemType = Object.freeze({
  Ozellikler: "ozellikler",
  SikSorulanSorular: "sik-sorulan-sorular",
  EkranGoruntuleri: "ekran-goruntuleri",
  KullanimKilavuzu: "kullanim-kilavuzu",
  Referanslar: "referanslar",
  Paketler: "paketler",
})

export const PageType = Object.freeze({
  Kutuphane: "kutuphane-programi",
  CekilisSite: "cekilis-sitesi",
  CekilisProgram: "cekilis-programi",
  AkilliKilit: "akilli-tahta-kilit",
  Servishane: "servishane",
})

const SoftwareSideMenu = ({
  parentPage,
  menuItems = [],
  activeMenuItem = MenuItemType.Ozellikler,
}) => {
  const {
    genel: {
      kullanimKilavuzu,
      ozellikler,
      sikSorulanSorular,
      ekranGoruntuleri,
      referanslar,
      paketler,
    },
  } = useTranslations()
  const getItemText = item => {
    switch (item) {
      case MenuItemType.Ozellikler:
        return ozellikler
      case MenuItemType.SikSorulanSorular:
        return sikSorulanSorular
      case MenuItemType.EkranGoruntuleri:
        return ekranGoruntuleri
      case MenuItemType.KullanimKilavuzu:
        return kullanimKilavuzu
      case MenuItemType.Referanslar:
        return referanslar
      case MenuItemType.Paketler:
        return paketler
      default:
        return ozellikler
    }
  }

  const renderMenuItems = menuItems.map((item, index) => (
    <LocalizedLink
      key={index}
      className={`nav-link ${activeMenuItem === item && "active"}`}
      to={`/${parentPage}/${item}/`}
    >
      {getItemText(item)}
    </LocalizedLink>
  ))

  return (
    <div>
      {kullanimKilavuzu && (
        <div
          className="nav flex-column nav-pills position-sticky float-right mr-5 d-none d-lg-block"
          id="v-pills-tab"
          aria-orientation="vertical"
        >
          {renderMenuItems}
        </div>
      )}
    </div>
  )
}

SoftwareSideMenu.propTypes = {
  parentPage: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.string),
  activeMenuItem: PropTypes.string,
}

export default SoftwareSideMenu
