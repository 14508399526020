import { graphql, useStaticQuery } from "gatsby"

import { Helmet } from "react-helmet"
import LocaleContext from "../../localeContext"
import PropTypes from "prop-types"
import React from "react"

function Facebook({ url, type, title, desc, image, locale }) {
  return (
    <Helmet>
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:alt" content={desc} />
      <meta property="og:image:width" content="851" />
      <meta property="og:image:height" content="315" />
    </Helmet>
  )
}

Facebook.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.string,
  locale: PropTypes.string,
}

function Twitter({
  type = "summary_large_image",
  username,
  title,
  desc,
  image,
}) {
  return (
    <Helmet>
      {username && <meta name="twitter:site" content={username} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={desc} />
    </Helmet>
  )
}

Twitter.propTypes = {
  type: PropTypes.string,
  username: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.string,
}

export default function SEO({
  title,
  titleSeparator,
  description,
  pathname,
  article = false,
  image = "https://www.kodvizit.com/img/cover.png",
  siteLanguage = "tr",
  siteLocale = "tr_TR",
  twitterUsername = "@kodvizit",
  author = "kodvizit",
  datePublished,
  dateModified,
  breadcrumbList,
  robots = "max-snippet:-1, max-image-preview:large, max-video-preview:-1",
}) {
  let baseUrl = "https://www.kodvizit.com"
  const { locale } = React.useContext(LocaleContext)
  if (locale) {
    siteLanguage = locale // tr
    siteLocale = `${locale}_${locale === "en" ? "US" : "TR"}`

    if (!locale !== "tr" && pathname) {
      pathname = pathname.replace(".com/", `.com/${siteLanguage}/`)
      baseUrl += `/${siteLanguage}`
    }
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const seo = {
    title: title.slice(0, 70),
    description: description.slice(0, 160),
    datePublished: datePublished
      ? datePublished
      : new Date(Date.now()).toISOString(),
    dateModified: dateModified
      ? dateModified
      : new Date(Date.now()).toISOString(),
  }

  const copyrightYear = new Date().getFullYear()

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  // Structured Data Testing Tool >>
  // https://search.google.com/structured-data/testing-tool

  const organization = {
    "@type": "Corporation",
    name: "kodvizit",
    alternateName: "Kodvizit",
    url: baseUrl,
    logo: "https://www.kodvizit.com/logo.png",
    sameAs: [
      "https://www.facebook.com/kodvizit",
      "https://www.twitter.com/kodvizit",
      "https://www.instagram.com/kodvizit",
      "https://www.youtube.com/channel/UCRLaEqZ-e7GaWG7CRHwweQA",
      "https://www.linkedin.com/company/kodvizit/",
      "https://www.github.com/kodvizit",
      "https://www.kodvizit.com",
    ],
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@type": "WebPage",
        "@id": baseUrl,
        url: baseUrl,
        name: "Ana sayfa",
      },
      position: 1,
    },
  ]

  let position = 2

  breadcrumbList &&
    breadcrumbList.forEach(item => {
      itemListElement.push({
        "@type": "ListItem",
        item: {
          "@type": "WebPage",
          "@id": item.path,
          url: item.path,
          name: item.name,
        },
        position: position,
      })
      position = position + 1
    })

  if (!(breadcrumbList && breadcrumbList.length === 0)) {
    // Push current blog post into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@type": "WebPage",
        "@id": pathname,
        url: pathname,
        name: seo.title,
      },
      position: position,
    })
  }
  let breadcrumb = {
    "@type": "BreadcrumbList",
    "@id": pathname + "#breadcrumb",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }
  breadcrumb = breadcrumbList && breadcrumbList[0] == "404" ? {} : breadcrumb

  const schemaOrgWebPage = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": `${pathname}/#website"`,
        url: baseUrl,
        name: "kodvizit",
        inLanguage: "tr",
        description: "yazılım üretim ve bakım merkezi",
      },
      {
        "@type": "WebPage",
        "@id": pathname + "#webPage",
        url: pathname,
        headline: seo.description,
        inLanguage: siteLanguage,
        mainEntityOfPage: pathname,
        description: seo.description,
        name: seo.title,
        isPartOf: {
          "@id": `${pathname}/#website"`,
        },
        breadcrumb: {
          "@id": pathname + "#breadcrumb",
        },
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [pathname],
          },
        ],
        author: organization,
        copyrightHolder: organization,
        copyrightYear,
        creator: organization,
        publisher: organization,
        datePublished: seo.datePublished,
        dateModified: seo.dateModified,
        image: {
          "@type": "ImageObject",
          url: `${image}`,
        },
      },
      breadcrumb,
    ],
  }

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear,
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${image}`,
        },
      },
      datePublished: seo.datePublished,
      dateModified: seo.dateModified,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: pathname,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: image,
      },
      mainEntityOfPage: pathname,
    }
  }

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={`%s ${titleSeparator ? titleSeparator : `·`} ${
          site.siteMetadata.title
        }`}
      >
        <html lang={siteLanguage ? siteLanguage : "tr"} />
        <link rel="canonical" href={pathname} />
        <meta name="description" content={seo.description} />
        {robots && <meta name="robots" content={robots} />}

        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <noscript>{`
        Please activate javascript to view kodvizit.com
    `}</noscript>
      </Helmet>
      {image && (
        <>
          <Facebook
            desc={seo.description}
            image={image}
            title={seo.title}
            type={article ? "article" : "website"}
            url={pathname}
            locale={siteLocale ? siteLocale : "tr_tr"}
          />
          <Twitter
            title={seo.title}
            image={image}
            desc={seo.description}
            username={twitterUsername}
          />
        </>
      )}
    </>
  )
}
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  titleSeparator: PropTypes.string,
  description: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  article: PropTypes.bool,
  image: PropTypes.string,
  siteLanguage: PropTypes.string,
  siteLocale: PropTypes.string,
  twitterUsername: PropTypes.string,
  author: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  breadcrumbList: PropTypes.arrayOf(PropTypes.object),
  robots: PropTypes.string,
}
