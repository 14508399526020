import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import useTranslations from "components/use-translations"

export default function SikSorulanSorular() {
  const {
    genel: { sikSorulanSorular },
    servishaneSSS: { seoTitle, seoDescription, sss },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/servishane/sik-sorulan-sorular"
        datePublished="2020-02-21T16:07:06+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          { path: "https://www.kodvizit.com/servishane", name: "Servishane" },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.Servishane}
          activeMenuItem={MenuItemType.SikSorulanSorular}
        />
        <Row>
          <Col>
            <h1 className="mb-4">{sikSorulanSorular}</h1>
            <Accordion defaultActiveKey="0">
              {sss.map((item, index) => (
                <Card key={index}>
                  <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                    <strong>{item.soru}</strong>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body>{item.cevap}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>
          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.Servishane}
              menuItems={[
                MenuItemType.Paketler,
                MenuItemType.SikSorulanSorular,
                MenuItemType.EkranGoruntuleri,
              ]}
              activeMenuItem={MenuItemType.SikSorulanSorular}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
